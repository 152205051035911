.header[data-v-1c634348] {
  width: 100%;
  height: 50px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 10px 10px 0;
}
[data-v-1c634348] .bjs-powered-by {
  display: none;
}